import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StoreService } from './store.service';
import {
    CategoriesResponse,
    SiteHeader,
    TrekHeadersResultNew
} from '../../../typings/api';
import { environment } from '@env';

@Injectable({ providedIn: 'root' })
export class SharedService {
    constructor(
        private http: HttpClient,
        private storeService: StoreService
    ) {}

    getTreksHeader(
        treksIds: Array<string>,
        siteId?: string,
        get_campaigns_data = true
    ): Observable<TrekHeadersResultNew> {
        const fields =
            'trek_id,trek_name,trek_name_lang,country,location,user_id,user_name,start_point,segment,trek_privacy,media_privacy,is_in_wish_list,master_media,start_date,site_logos,time,difficulty,has_pending_media,tz_offset';
        const locale = this.storeService.getRequestLang();
        const params: any = {
            locale,
            trek_ids: treksIds.join(','),
            fields
        };
        if (siteId) {
            params.site_ref = siteId;
        }
        if (!get_campaigns_data) {
            params.get_campaigns_data = false;
        }
        return this.http.get<TrekHeadersResultNew>(
            `${environment.GATE_URL}/v2/trek/`,
            { params }
        );
    }

    getSiteHeader(sitesIds: string[]): Observable<SiteHeader[]> {
        return this.http.post<SiteHeader[]>(
            `${environment.GATE_URL}/site/headers`,
            {
                sites: sitesIds
            }
        );
    }

    getCategories(locale: string): Observable<CategoriesResponse> {
        const config = {
            headers: {
                withCredentialsRemove: 'true'
            },
            params: {}
        };
        return this.http.get<CategoriesResponse>(
            `${environment.CATEGORIES_DATA_URL}/data/ooiCategories_${locale}.json`,
            config
        );
    }
}
